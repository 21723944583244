import { loadStripe } from "@stripe/stripe-js";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export var offerID = "test_offer";
export const useTestPayments = true;

if (process.env.NODE_ENV === "development" && useTestPayments) {
	offerID = "TEST_VIP_BASIC_monthly";
	// offerID = "test_offer";
	// offerID = "BASIC_VIP_monthly";
} else {
	offerID = "VIP_BASIC_monthly";
}

var stripePublicKey;

if (process.env.NODE_ENV === "development" && useTestPayments) {
	stripePublicKey =
		"pk_test_51NpRYcE263KsJdwOegYAyi8kEoLfdMu5rd1uW4a3hS8jDmLVVPzD7E5q6xq8tM5eVehXGDgOQ4FMsoC6soKCAEJs00vt5SeKRH";
} else {
	stripePublicKey =
		"pk_live_51NpRYcE263KsJdwOmcAfya7mlL60NMJKuQ0xjH04waSAibYIZkuFO8kMuMId0hfws1TMsl8T368xRyjuHfAXSwIC0003c3RH3q";
}

export const stripePromise = loadStripe(stripePublicKey);
